<script>
import DefaultMixin from '../mixin'
import RelatorioMenu from "@/components/relatorios/components/Menu"

export default {
  name: 'ConfigLayout',
  provide: function () {
    return {
      page: this
    }
  },
  inject: ['erplayout'],
  mixins: [DefaultMixin],
  components: {
    RelatorioMenu
  },
  data () {
    return {
      active: 'hom'
    }
  },
  computed: {},
  mounted() {
    setTimeout(() => {
      this.erplayout && this.erplayout.erpheader.setMenuModuleToggle(true)
    }, 500)
    this.setActive('relatorios')
    document.querySelector('.u-erp-layout-container').classList.add('relatorios')
  },
  beforeDestroy() {
    document.querySelector('.u-erp-layout-container').classList.remove('relatorios')
  },
  methods: {
    setActive (v) {
      this.active = v
    }
  },
  meta: {
    title: 'Relatórios',
    name: 'Relatórios'
  }
}
</script>

<template>
  <div class="relatorios-main">
    <div class="relatorios-container">
      <relatorio-menu />
      <div class="rel-page">
        <router-view />
      </div>
    </div>
  </div>
</template>

<style lang="stylus" src="../assets/default.styl"></style>
