<template>
  <div class="rel-menu">
    <div class="menu-content">
      <h2>Relatórios</h2>
      <ul v-for="mc in menu" :key="mc.id" :class="mc.class">
        <li class="menu-title">{{mc.label}}</li>
        <li v-for="m in mc.menu" :key="m.id">
          <a :class="{disabled: m.disabled, active: $route.name === m.route}" @click="click(m)"><u-icon class="fa-fw" :name="m.icon" :type="m.iconType || 'fa'" :icon-style="m.iconStyle" /> {{m.label}}</a>
        </li>
      </ul>
      <div style="height: 20px"></div>
    </div>
  </div>
</template>

<script>
import MenuJS from '../menu.js'

export default {
  name: 'RelatorioMenu',
  computed: {
    menu() {
      return MenuJS
    }
  },
  methods: {
    click (m) {
      if (m.disabled) return
      if (m.click) {
        m.click.call(this)
        return
      }
      if (m.route) {
        this.$router.push({name: m.route})
      }
    }
  }
}
</script>
