export default function () {
  let window = `relatorio.leiloes.livros.diario`
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Livros - Diário de Leilões',
    width: '600',
    height: '450',
    minHeight: '500px',
    backdrop: false,
    maximize: false,
    clickOutside: false,
    windowClass: 'erp-window',
    contentClass: 'overflow-hidden',
    props: {
    }
  }, () => import('./LivroDiario.vue'))
    .then((wid) => {
      console.log(wid)
    }) // return wid
}
