import estoqueWindow from "@/components/relatorios/components/bens/estoqueWindow"
import remocaoEntradaSaidaWindow from "@/components/relatorios/components/bens/remocaoEntradaSaidaWindow"
import remocaoPrevPagReboquistaWindow from "@/components/relatorios/components/bens/remocaoPrevPagReboquistaWindow"
import lotesWindow from "@/components/relatorios/components/leiloes/lotesWindow"
import compraPorArrematante from "@/components/relatorios/components/leiloes/compraPorArrematante"
import lancesWindow from "@/components/relatorios/components/leiloes/lancesWindow"
import leiloesWindow from "@/components/relatorios/components/leiloes/leiloesWindow"
import leiloesNegativosWindow from "@/components/relatorios/components/leiloes/leiloesNegativoWindow"
import ataRealizacaoWindow from "@/components/relatorios/components/leiloes/ataRealizacaoWindow"
import prestacaoContasWindow from "@/components/relatorios/components/leiloes/prestacaoContasWindow"
import veiculosConfirmacaoDadosWindow from "@/components/relatorios/components/leiloes/veiculosConfirmacaoDadosWindow"
import relacaoPatioWindow from "@/components/relatorios/components/leiloes/relacaoPatioWindow"
import analisePagamentoMaiorLoteWindow
    from "@/components/relatorios/components/leiloes/analisePagamentoMaiorLoteWindow"
import analisePagamentosLeilaoWindow from "@/components/relatorios/components/leiloes/analisePagamentosLeilaoWindow"
import analiseBensSemArquivoWindow from "@/components/relatorios/components/bens/analiseBensSemArquivoWindow"
import aniversariantesWindow from "@/components/relatorios/components/arrematantes/aniversariantesWindow"
import livroDiario from "@/components/relatorios/components/leiloes/livroDiarioWindow"
import relatorioTarefas from "@/components/relatorios/components/tarefas/relatorioTarefas"
import relatorioProecessoClientes from "@/components/relatorios/components/processos/relatorioClientes"
import relatorioProcessoSecretarias from "@/components/relatorios/components/processos/relatorioSecretarias"
import relatorioProcessos from "@/components/relatorios/components/processos/relatorioProcessos"
import relatorioBens from "@/components/relatorios/components/bens/relatorioBensWindow"

export default [
    {
        id: 'leiloes',
        label: 'Leilões',
        icon: 'gavel',
        iconType: 'fa',
        iconStyle: 'regular',
        menu: [
            {
                id: 'leiloes-resumo',
                label: 'Resumo de Leilões',
                icon: 'calendar-day',
                iconType: 'fa',
                click: leiloesWindow
            },
            {
                id: 'leiloes-negativos',
                label: 'Leilões Negativos',
                icon: 'calendar-day',
                iconType: 'fa',
                click: leiloesNegativosWindow
            },
            {
                id: 'leiloes-exportar-lotes',
                label: 'Exportar Lotes',
                icon: 'list',
                iconType: 'fa',
                click: function (){ lotesWindow.call(this, false) }
            },
            {
                id: 'leiloes-exportar-lotes-vendidos',
                label: 'Exportar Lotes Vendidos',
                icon: 'clipboard-check',
                iconType: 'fa',
                click: function (){ lotesWindow.call(this, true) }
            },
            {
                id: 'leiloes-exportar-lances',
                label: 'Exportar Lances',
                icon: 'hand-pointer',
                iconType: 'fa',
                click: lancesWindow
            },
            {
                id: 'leiloes-visualizacoes',
                label: 'Visualizações',
                icon: 'eye',
                iconType: 'fa',
                route: 'relatorio.leiloes-visualizacoes'
            },
            {
                id: 'leiloes-prestacao-contas',
                label: 'Prestação de Contas',
                icon: 'file-invoice',
                iconType: 'fa',
                click: prestacaoContasWindow
            },
            {
                id: 'leiloes-pagamentos-efetuados',
                label: 'Pagamentos efetuados',
                icon: 'file-invoice',
                iconType: 'fa',
                click: analisePagamentosLeilaoWindow
            },
            {
                id: 'leiloes-pagamentos-maior-devido',
                label: 'Pagamento Maior que Devido',
                icon: 'file-invoice-dollar',
                iconType: 'fa',
                click: analisePagamentoMaiorLoteWindow
            },
            {
                id: 'leiloes-veiculos-ata-realizacao',
                label: 'Veículos - Ata de Realização de Leilão',
                icon: 'car',
                iconType: 'fa',
                click: ataRealizacaoWindow
            },
            {
                id: 'leiloes-veiculos-confirmacao-dados',
                label: 'Veículos - Confirmação de Dados',
                icon: 'car',
                iconType: 'fa',
                click: veiculosConfirmacaoDadosWindow
            },
            {
                id: 'leiloes-veiculos-relacao-patio',
                label: 'Veículos - Relação para Pátio',
                icon: 'car',
                iconType: 'fa',
                click: relacaoPatioWindow
            },
            {
                id: 'leiloes-livro-diario',
                label: 'Livros - Diário de Leilões',
                icon: 'book',
                iconType: 'fa',
                click: livroDiario
            }
        ]
    },
    {
        id: 'bens',
        label: 'Bens',
        icon: 'wallet',
        iconType: 'fa',
        iconStyle: 'regular',
        menu: [
            {
                id: 'bens-relatorio',
                label: 'Relatório de Bens',
                icon: 'briefcase',
                iconType: 'fa',
                click: relatorioBens
            },
            {
                id: 'bens-estoque',
                label: 'Consulta de estoque',
                icon: 'receipt_long',
                iconType: 'material',
                click: estoqueWindow
            },
            {
                id: 'bens-sem-foto',
                label: 'Bens Sem Foto',
                icon: 'images',
                iconType: 'fa',
                click: analiseBensSemArquivoWindow
            },
            /*{
                id: 'bens-prazo-medio-venda',
                label: 'Prazo Médio de Venda',
                icon: 'hourglass-half',
                iconType: 'fa',
                disabled: true
            },*/
            {
                id: 'bens-mais-tempo-em-estoque',
                label: 'Bens com mais Tempo em Estoque',
                icon: 'clock',
                iconType: 'fa',
                route: 'relatorio.bens.tempoEstoque'
            }
        ]
    },
    /*{
        id: 'financeiro',
        label: 'Financeiro',
        class: '', // TMP
        icon: 'wallet',
        iconType: 'fa',
        iconStyle: 'regular',
        menu: [
            {
                id: 'financeiro-entradas',
                label: 'Entradas',
                icon: 'sort-numeric-down',
                iconType: 'fa',
                disabled: true
            },
            {
                id: 'financeiro-saidas',
                label: 'Saídas',
                icon: 'sort-numeric-up',
                iconType: 'fa',
                disabled: true
            },
            {
                id: 'financeiro-fluxo',
                label: 'Fluxo de Caixa',
                icon: 'money-check',
                iconType: 'fa',
                disabled: true
            },
            {
                id: 'financeiro-previsao',
                label: 'Previsão Orçamentária',
                icon: 'file-chart-line',
                iconType: 'fa',
                disabled: true
            }
        ]
    },*/
    {
        id: 'remocao',
        label: 'Remoção',
        icon: 'wallet',
        iconType: 'fa',
        iconStyle: 'regular',
        menu: [
            {
                id: 'remocao-entradas-saidas',
                label: 'Entrada / Saída de Veículos no Pátio',
                icon: 'sort-numeric-down',
                iconType: 'fa',
                click: remocaoEntradaSaidaWindow
            },
            {
                id: 'remocao-previsao-pagamento-reboquistas',
                label: 'Previsão de Pagamento de Reboquistas',
                icon: 'cash-register',
                iconType: 'fa',
                click: remocaoPrevPagReboquistaWindow
            },
            /*{
                id: 'remocao-km',
                label: 'Quilometragem Percorrida',
                icon: 'route',
                iconType: 'fa',
                disabled: true
            }*/
        ]
    },
    {
        id: 'tarefas',
        label: 'Tarefas',
        icon: 'wallet',
        iconType: 'fa',
        iconStyle: 'regular',
        menu: [
            {
                id: 'task-indicador-diario',
                label: 'Indicador Diário',
                icon: 'calendar-alt',
                iconType: 'fa',
                click: relatorioTarefas
            }
        ]
    },
    {
        id: 'processos',
        label: 'Processos',
        icon: 'wallet',
        iconType: 'fa',
        iconStyle: 'regular',
        menu: [
            {
                id: 'processos-clientes',
                label: 'Clientes / Evolvimento / Parte',
                icon: 'user',
                iconType: 'fa',
                click: relatorioProecessoClientes
            },
            {
                id: 'processos-secretarias',
                label: 'Secretarias',
                icon: 'university',
                iconType: 'fa',
                click: relatorioProcessoSecretarias
            },
            {
                id: 'processos-geral',
                label: 'Processos',
                icon: 'book',
                iconType: 'fa',
                click: relatorioProcessos
            },
            {
                id: 'processos-produtividade',
                label: 'Produtividade',
                icon: 'user-graduate',
                iconType: 'fa',
                route: 'relatorio.produtividade.periodo'
            }
        ]
    },
    {
        id: 'arrematantes',
        label: 'Arrematantes',
        icon: 'wallet',
        iconType: 'fa',
        iconStyle: 'regular',
        menu: [
            /*{
                id: 'arrematantes-analise-cadastral',
                label: 'Análise Cadastral',
                icon: 'users',
                iconType: 'fa',
                disabled: true
            },
            {
                id: 'arrematantes-prazo-medio-envio-docs',
                label: 'Prazo Médio do Envio dos Documentos',
                icon: 'clock',
                iconType: 'fa',
                disabled: true
            },
            {
                id: 'arrematantes-prazo-medio-aprovacao',
                label: 'Prazo Médio de Aprovação',
                icon: 'clock',
                iconType: 'fa',
                disabled: true
            },
            {
                id: 'arrematantes-inadimplentes',
                label: 'Inadimplentes',
                icon: 'stop',
                iconType: 'fa',
                disabled: true
            },
            {
                id: 'arrematantes-restricoes',
                label: 'Arrematantes com Restrições',
                icon: 'stop',
                iconType: 'fa',
                disabled: true
            },*/
            {
                id: 'arrematantes-compras',
                label: 'Compras',
                icon: 'shopping-cart',
                iconType: 'fa',
                click: compraPorArrematante
            },
            {
                id: 'arrematantes-mais-compras',
                label: 'Arrematantes que Mais Compraram',
                icon: 'star',
                iconType: 'fa',
                disabled: true
            },
            {
                id: 'arrematantes-aniversariantes',
                label: 'Aniversariantes do Mês',
                icon: 'calendar',
                iconType: 'fa',
                click: aniversariantesWindow
            },
            {
                id: 'arrematantes-export',
                label: 'Exportar Arrematantes',
                icon: 'file-spreadsheet',
                iconType: 'fa',
                route: 'relatorio.arrematantes.exportar'
            }
        ]
    },
    {
        id: 'comitentes',
        label: 'Comitentes',
        icon: 'wallet',
        iconType: 'fa',
        iconStyle: 'regular',
        class: '', // TMP
        menu: [
            {
                id: 'comitentes-export',
                label: 'Exportar Comitentes',
                icon: 'file-spreadsheet',
                iconType: 'fa',
                disabled: true
            },
        ]
    },
    {
        id: 'colaboradores',
        label: 'Colaboradores',
        icon: 'wallet',
        iconType: 'fa',
        iconStyle: 'regular',
        menu: [
            {
                id: 'colaboradores-worktime',
                label: 'Tempo de Trabalho',
                icon: 'clock',
                iconType: 'fa',
                route: 'relatorio.colab.tempoTrabalho'
            },
            {
                id: 'colaboradores-operacional-log',
                label: 'Log Operacional',
                icon: 'terminal',
                iconType: 'fa',
                route: 'relatorio.operacional.logs'
            }
        ]
    },
    {
        id: 'documentos',
        label: 'Documentos',
        icon: 'wallet',
        iconType: 'fa',
        iconStyle: 'regular',
        class: '', // TMP
        menu: [
            {
                id: 'documentos-validade',
                label: 'Validade de Documentos',
                icon: 'calendar-day',
                iconType: 'fa',
                disabled: true
            },
        ]
    },
    {
        id: 'software',
        label: 'Software',
        icon: 'wallet',
        iconType: 'fa',
        iconStyle: 'regular',
        class: '', // TMP
        menu: [
            {
                id: 'software-api-error',
                label: 'API Error',
                icon: 'exclamation-triangle',
                iconType: 'fa',
                disabled: true
            },
        ]
    }
]
