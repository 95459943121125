export default function () {
    let window = `relatorio.processo.secretarias`
    !this.$uloc.window.get(window) && this.$uloc.window.new({
      wid: window,
      title: 'Relatório de Secretarias',
      width: '600',
      height: '450',
      minHeight: '500px',
      backdrop: false,
      maximize: false,
      clickOutside: false,
      windowClass: 'erp-window',
      contentClass: 'overflow-hidden',
      props: {
      }
    }, () => import('./Secretarias.vue'))
      .then((wid) => {
        console.log(wid)
      }) // return wid
  }
  