export default function () {
  let window = `relatorio.arrematantes.aniversariantes`
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Relatório de Aniversariantes do Mês',
    width: '500',
    height: '200',
    minHeight: '200px',
    backdrop: false,
    maximize: false,
    clickOutside: false,
    windowClass: 'erp-window',
    contentClass: 'overflow-hidden',
    props: {
    }
  }, () => import('./Aniversariantes.vue'))
    .then((wid) => {
      console.log(wid)
    }) // return wid
}
