export default function () {
  let window = `relatorio.bens.analiseSemArquivo`
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Análise de Bens com Ausência de Arquivo',
    width: '600',
    height: '400',
    minHeight: '400px',
    backdrop: false,
    maximize: false,
    clickOutside: false,
    windowClass: 'erp-window',
    contentClass: 'overflow-hidden',
    props: {
      router: this.$router,
      leilaoId: this.leilao ? this.leilao.id : null
    }
  }, () => import('./BensSemArquivo.vue'))
    .then((wid) => {
      console.log(wid)
    }) // return wid
}
