export default function (id) {
  let window = `relatorio.estoque`
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Relatório de Estoque',
    width: '600',
    height: '500',
    minHeight: '500px',
    backdrop: false,
    clickOutside: false,
    windowClass: 'erp-window',
    contentClass: 'overflow-hidden',
    props: {
      id: id
    }
  }, () => import('./Estoque.vue'))
    .then((wid) => {
      console.log(wid)
    }) // return wid
}
